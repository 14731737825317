<template>
  <div class="solution">
    <div class="banner">
      <div class="left">
        <div class="title_box">
          <div class="big_title">提供丰富的项目管理解决方案</div>
          <div class="small_title">根 据 客 户 所 在 行 业 和 体 量 不 同 角 度，为 客 户 提 供 专 属 的 数 字 化 项 目 管 理 咨 询 规 划 方 案</div>
        </div>
      </div>
    </div>

    <div style="padding-bottom: 1.2rem;" class="solution_contact">
      <div class="title">克里特数字化 · 重点方案 </div>
      <div class="subtitle">深入细分场景，提供准确的行业解决方案，打造一体化的项目管理平台</div>
      <div class="inside">
        <div @click="jumpSurvey" class="inside_item">
          <div class="img1"></div>
          <div class="title_container">
            <div class="inside_title">勘察设计管理系统开发</div>
            <div class="inside_container">适用于通信设计行业单位，前端勘察、
后端绘图工序分离，并形成生产成果的
积累沉淀，进一步转化为生产力。</div>
          </div>
        </div>
        <div @click="jumpHuading" class="inside_item">
          <div class="img2"></div>
          <div class="title_container">
            <div class="inside_title">服装平台开发</div>
            <div class="inside_container">一个创新的平台，让服装设计师、客户和工厂之间能够高效地进行协同合作及全流程管理。 </div>
          </div>
        </div>
        <div @click="jumpApartment" class="inside_item">
          <div class="img3"></div>
          <div class="title_container">
            <div class="inside_title">智慧公寓管理系统</div>
            <div class="inside_container">一种集成智能设备和互联网技术的管理软件，提升公寓管理效率和居民生活体验</div>
          </div>
        </div>
        <div @click="jumpSchool" class="inside_item">
          <div class="img4"></div>
          <div class="title_container">
            <div class="inside_title">高校实验室系统开发</div>
            <div class="inside_container">数字化管理提高实验室管理效率和实验室资源利用率，并且便于管理者对实验室运行情况进行实时监控和数据分析。</div>
          </div>
        </div>
        <div @click="jumpSmartAquaculture" class="inside_item">
          <div class="img5"></div>
          <div class="title_container">
            <div class="inside_title">智慧养殖系统开发</div>
            <div class="inside_container">提供数据管理、养殖生产、环境检测、设备控制、溯源管理等。实现智能化养殖，缩短养殖周期，提高养殖产量。</div>
          </div>
        </div>
        <div @click="jumpFixedAssets" class="inside_item">
          <div class="img6"></div>
          <div class="title_container">
            <div class="inside_title">固定资产管理系统开发</div>
            <div class="inside_container">一个帮助公司有效跟踪、管理和优化固定资产的软件系统，合规的管理、资产利用率分析让企业管理更便捷高效。</div>
          </div>
        </div>
        <div @click="jumpMeeting" class="inside_item">
          <div class="img7"></div>
          <div class="title_container">
            <div class="inside_title">会议管理系统</div>
            <div class="inside_container">旨在通过数字化手段提升会议组织效率，简化流程，确保会议信息的准确传递与高校管理。</div>
          </div>
        </div>
        <div @click="jumpHouQing" class="inside_item">
          <div class="img8"></div>
          <div class="title_container">
            <div class="inside_title">后勤管理系统</div>
            <div class="inside_container">一个用于跟踪、安排和优化维修活动确保设备、设施、场所正常运行的综合软件平台。</div>
          </div>
        </div>
        <div @click="jumpYinZang" class="inside_item">
          <div class="img9"></div>
          <div class="title_container">
            <div class="inside_title">印章管理系统</div>
            <div class="inside_container">将印章设备与系统相结合，用于监控、追踪和管理印章使用流程及安全的数字化工具。</div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: white;padding-bottom: 5.6rem;" class="solution_contact">
      <div class="title">克里特数字化 · 更多方案 </div>
      <div class="subtitle">克里特数字化平台为医药、投资、检测等行业提供一体化解决方案</div>
      <div class="inside">
        <div class="item">
          <div class="item_inside">
            <div class="only_one">
              <div class="only_one_title">园区机构</div>
              <div class="only_one_container">助力园区机构单位覆盖各类投资项目的全过程、全要素、全参与的项目管理，让项目管理便捷高效。</div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;" class="item_inside">
            <div class="only_two_blue">
              <div class="only_two_title">通信</div>
              <div class="only_two_container">
                致力于为客户提供优质的通信网20络咨询及设计服务，涉及无线、有线、数据、土建等专业。
              </div>
            </div>
            <div class="only_two_green">
              <div class="only_two_title">勘察</div>
              <div class="only_two_container">
                通过集成多种勘察设计工具和技术，提供自动化、智能化的辅助功能，以提高勘察设计的效率。
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div style="display: flex;justify-content: space-between;" class="item_inside">
            <div class="only_two_blue">
              <div class="only_two_title">咨询</div>
              <div class="only_two_container">
                涵盖业务开拓与办理，投标管门理、合同管理、专家库、项目结算、奖金分配等全过程管理..
              </div>
            </div>
            <div class="only_two_green">
              <div class="only_two_title">地产</div>
              <div class="only_two_container">
                助力企业数字化转型和创新，实现地产项目全周期管理，构建新时代地产企业核心竞争力。
              </div>
            </div>
          </div>
          <div class="item_inside">
            <div class="only_one">
              <div class="only_one_title">系统集成</div>
              <div class="only_one_container">
                涵盖安防工程、IDC机房、建筑智能化等领域的信息系统集成，提高系
                统的整体效率和性能，减少重复数据输入和操作，增强系统的灵活性
                和可靠性，促进组织的数字化转型和业务发展....
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    jumpSmartAquaculture() {
      this.$router.push({
        path:'/pc/smartAquaculture'
      })
    },
    jumpSurvey() {
      this.$router.push({
        path:'/pc/survey'
      })
    },
    jumpSchool() {
      this.$router.push({
        path:'/pc/school'
      })
    },
    jumpHuading() {
      this.$router.push({
        path:'/pc/huading'
      })
    },
    jumpFixedAssets() {
      this.$router.push({
        path:'/pc/fixedAssets'
      })
    },
    jumpApartment() {
      this.$router.push({
        path:'/pc/apartment'
      })
    },
    jumpMeeting() {
      this.$router.push({
        path:'/pc/meeting'
      })
    },
    jumpHouQing() {
      this.$router.push({
        path:'/pc/houqing'
      })
    },
    jumpYinZang() {
      this.$router.push({
        path:'/pc/yinzhang'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.solution {
  .banner {
    min-width: 1024px;
    width: 100%;
    height: 20rem;
    background-image: url('../../../assets/solutionBanner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 23.7264rem;
      height: 14rem;
      //background: red;
      padding-left: 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title_box {
        height: 5.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .big_title {
          font-weight: 600;
          font-size: 1.6rem;
          color: #000000;
        }

        .small_title {
          font-weight: 500;
          font-size: 0.82rem;
          color: #000000;
          line-height: 1.2rem;
        }
      }
    }
  }

  .solution_contact {
    min-width: 1024px;
    width: 100%;
    padding-top: 3.2rem;
    padding-bottom: 4rem;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4F3F3;

    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 2.4rem;
    }

    .inside {
      width: 41.52rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .inside_item {
        margin-bottom: 0.6rem;
        width: 32%;
        cursor: pointer;

        .img1 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/solution2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .img2 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/solution3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .img3 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/solution4.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .img4 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/solution5.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .img5 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/solution6.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .img6 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/solution7.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .img7 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/会议管理系统.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        
        .img8 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/后勤管理系统.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        
        .img9 {
          width: 100%;
          height: 8.96rem;
          background-image: url('../../../assets/印章管理系统.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }


        .title_container {
          width: 88%;
          height: 4.4rem;
          padding: 0.8rem;
          background: white;

          .inside_title {
            font-size: 0.72rem;
            font-weight: 600;
            margin-bottom: 0.4rem;
          }

          .inside_container {
            font-size: 0.64rem;
          }
        }
      }

      .item {
        width: 49%;
        height: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .item_inside {
          height: 45%;
          width: 100%;

          .only_one {
            height: 82%;
            padding: 0.4rem;
            background-image: url('../../../assets/solution8.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: #FFFFFF;

            .only_one_title {
              font-size: 0.64rem;
              // font-weight: 600;
              margin-bottom: 0.4rem;
            }

            .only_one_container {
              font-size: 0.56rem;
              line-height: 0.8rem;
            }
          }

          .only_two_blue {
            width: 45%;
            height: 82%;
            background-image: url('../../../assets/solution10.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: #FFFFFF;
            padding: 0.4rem;

            .only_two_title {
              font-size: 0.64rem;
              // font-weight: 600;
              margin-bottom: 0.4rem;
            }

            .only_two_container {
              font-size: 0.56rem;
              line-height: 0.8rem;
            }
          }

          .only_two_green {
            width: 45%;
            height: 82%;
            background-image: url('../../../assets/solution9.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            color: #FFFFFF;
            padding: 0.4rem;

            .only_two_title {
              font-size: 0.64rem;
              // font-weight: 600;
              margin-bottom: 0.4rem;
            }

            .only_two_container {
              font-size: 0.56rem;
              line-height: 0.8rem;
            }
          }
        }
      }
    }
  }
}
</style>