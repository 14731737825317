<template>
  <div class="product_introduction">
    <div class="banner">
      <div class="left">
        <div class="title_box">
              <div class="image-and-text">
              <img src="../../../assets/克里特易.png" alt="图标" class="kelete"><div class="big_title">数字化运营平台核心功能</div>
            </div>
          <div class="small_title">实 现 组 织 中 的 各 类 项 目 “ 知 进 度 · 提 效 率 · 控 成 本 · 防 风 险 ”</div>
        </div>
      </div>
    </div>

    <div class="product_introduction_contact">
      <transition name="animate__animated animate__bounce" appear enter-active-class="animate__fadeInDown">
        <div class="image-and-text"><img src="../../../assets/keleteyi.png" alt="图标" class="keletebg1"><div class="title"> 核心亮点 </div></div>
      </transition>
      <transition name="animate__animated animate__bounce" appear enter-active-class="animate__fadeInDown">
        <div class="subtitle">对企业里各类事项任务的安排、执行和反馈，解决企业运营过程的成本和收支的核算 </div>
      </transition>
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInUp">
        <div v-show="scrollPosition >= 100" class="highlights">
          <div class="highlights_item">
            <div class="img1"></div>
            <div class="main_title">以企业为中心的协同运作</div>
            <div class="main_container">
              写合同、客户、采购、财务、“人事和项目的协同性;数据可以通过工作流引擎相互调用，从系统平台上可以多维了解相关合同、客户、采购、费用、绩效和文档知识
            </div>
          </div>
          <div class="highlights_item">
            <div class="img2"></div>
            <div class="main_title">数字化和智能化的建设能力</div>
            <div class="main_container">
              让数字审核、数学管理、数字档案融
              入到系统平台中，帮助实现企业的全
              程数字化办公。赋予企业具备可信数
              字化的建设能力，同时融入智能化组
              件让系统平台更加智能
            </div>
          </div>
          <div class="highlights_item">
            <div class="img3"></div>
            <div class="main_title"> 产品化平台，提供随需构建能力</div>
            <div class="main_container">
              可根据客户需求首由定义，我们提供
              不同的产品模块，可根据需求快速搭
              建一个成熟可用的平台系统，让企业
              具备持续数字化的能力
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="product_introduction_application_architecture">
      <div class="image-and-text"><img src="../../../assets/keleteyi.png" alt="图标" class="keletebg1"><div class="title"> 应用架构平台</div> </div>
      <div class="subtitle">全方位数字化管控，让企业管理随时跟踪，项目风险随时可控，企业成本利润精准核算</div>
      <div class="bg"></div>
    </div>

    <div style="background-color: #F4F3F3;" class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 1530" class="title">产品功能 · 系统集成</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 1530" class="explain">
            借助CIP集成管理平台，可与ERP、HR、CRM、PLM、BI等第三方系统集成，实现主数据统一、消息/待办统一、门户统一、业务流程统一、业务报表统一、身份认证统一。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 1530" class="image1"></div>
        </transition>

      </div>
    </div>

    <div class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 2451" class="title">产品功能 · 日程任务</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 2451" class="explain">
            包括任务清单、优先级确定、时间安排、资源分配和执行监督等方面。通过制定日程任务，个人和部门可以更好地组织时间和资源，高效地完成各项工作和活动。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 2451" class="image2"></div>
        </transition>
      </div>
    </div>

    <div style="background-color: #F4F3F3;" class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 3300" class="title">产品功能 · 流程管理</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 3300" class="explain">
            与各类异构系统集成，各系统统一待办，审批统一进行，一个门户完成所有工作。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 3300" class="image3"></div>
        </transition>
      </div>
    </div>

    <div class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 4100" class="title">产品功能 · 用户管理</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 4100" class="explain">
            用户的创建、删除、修改、权限分配、身份验证等操作。
            管理员可以通过用户管理功能对系统中的用户账号进行管理和控制，确保系统安全、稳定和高效运行。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 4100" class="image4"></div>
        </transition>
      </div>
    </div>

    <div style="background-color: #F4F3F3;" class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 4750" class="title">产品功能 · 在线文档</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 4750" class="explain">
            系统内的在线文档可以随时更新，保持与系统最新版本的同步，方便企业内员工及时获取最新信息，且工作成果及时在系统留痕。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 4750" class="image5"></div>
        </transition>
      </div>
    </div>

    <div class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 5550" class="title">产品功能 · 预算执行</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 5550" class="explain">
            监控和跟踪预算执行情况，确保资金使用的合理性，及时调整预算计划以适应变化的经济环境和市场需求。帮助企业在实现财务目标的同时保持良好的财务状况。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 5550" class="image6"></div>
        </transition>
      </div>
    </div>

    <div style="background-color: #F4F3F3;" class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 6400" class="title">产品功能 · 资产管理</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 6400" class="explain">
            实现对固定、无形、实物、耗材和设备等资产的全生命周期管理。同时，通过与采购管理、合同管理等业务数据共享实现资产闭环管理。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 6400" class="image7"></div>
        </transition>
      </div>
    </div>

    <div class="product_features">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 7150" class="title">产品功能 · 党建管理</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 7150" class="explain">
            覆盖党建工作全维度应用，多终端覆盖，丰富的Ai交互功能，助力高校开启只会党建新时代。</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 7150" class="image8"></div>
        </transition>
      </div>
    </div>

    <div class="product_features">

      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 7900" class="title">产品功能 · 移动项目运用</div>
      </transition>
      <div class="container">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 7900" class="explain">
            移动项目应用支持项目管理系统功能和数据的PC、移动一体化，移动、H5等多端适配</div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 7900" class="image_list">
            <div class="image_list_item1"></div>
            <div class="image_list_item2"></div>
            <div class="image_list_item3"></div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY;
    });
  }
}
</script>

<style lang="scss" scoped>
.product_introduction {
  .banner {
    min-width: 1024px;
    width: 100%;
    height: 20rem;
    background-image: url('../../../assets/productIntroductionBanner.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 46%;
      height: 70%;
      //background: red;
      padding-left: 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title_box {
        height: 4.1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        .image-and-text {
             display: flex; // 使用 Flexbox 来管理子元素的布局
             align-items: center; // 垂直居中对齐子元素
          }
        .kelete{
        width: 180px;
        margin-right: 12px;
        margin-top: 4px;
        }
        .big_title {
          font-weight: 600;
          font-size: 1.6rem;
          color: #000000;
        }

        .small_title {
          font-weight: 500;
          font-size: 0.82rem;
          color: #000000;
        }
      }
    }
  }

  .product_introduction_contact {
    min-width: 1024px;
    width: 100%;
    padding-top: 3.2rem;
    padding-bottom: 4rem;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4F3F3;
    .image-and-text {
             display: flex; // 使用 Flexbox 来管理子元素的布局
             align-items: center; // 垂直居中对齐子元素
             .keletebg1{
      width: 100px;
      margin-right: 10px;
      margin-bottom: 15px;
    }
          }
    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 2.4rem;
    }

    .highlights {
      width: 70%;
      display: flex;
      justify-content: space-between;

      .highlights_item {
        width: 26%;
        height: 11.6rem;
        padding: 0.8rem 0.8rem 0px 0.8rem;
        background: #e9eeff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img1 {
          width: 1.8rem;
          height: 1.6rem;
          background-image: url('../../../assets/productIntroduction2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: 0.8rem;
        }

        .img2 {
          width: 1.6rem;
          height: 1.8rem;
          background-image: url('../../../assets/productIntroduction3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: 0.6rem;
        }

        .img3 {
          width: 2rem;
          height: 1.9rem;
          background-image: url('../../../assets/productIntroduction4.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: 0.6rem;
        }

        .main_title {
          font-size: 0.72rem;
          font-weight: 600;
          margin-bottom: 0.8rem;
        }

        .main_container {
          font-size: 0.64rem;
          color: #999999;
          line-height: 1rem;
        }
      }
    }
  }

  .product_introduction_application_architecture {
    width: 100%;
    padding-top: 3.2rem;
    padding-bottom: 4.8rem;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1024px;
    .image-and-text {
             display: flex; // 使用 Flexbox 来管理子元素的布局
             align-items: center; // 垂直居中对齐子元素
             .keletebg1{
      width: 120px;
      margin-right: 10px;
      margin-bottom: 12px;
    }
    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }
          }


    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 1.2rem;
    }

    .bg {
      width: 85%;
      height: 27.52rem;
      background-image: url('../../../assets/productIntroduction16.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .product_features {
    min-width: 1024px;
    width: 100%;
    padding-top: 2.4rem;
    padding-bottom: 3.2rem;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 0.88rem;
      font-weight: 600;
      margin-bottom: 1.6rem;
    }

    .container {
      width: 85%;
      display: flex;

      .explain {
        width: 8rem;
        font-size: 0.64rem;
        color: #999999;
        margin-right: 2rem;
        line-height: 1rem;
      }

      .image1 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction5.png');
        background-size:100% 110%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image2 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction6.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image3 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction7.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image4 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction8.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image5 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction9.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image6 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction10.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image7 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction11.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image8 {
        width: 80%;
        height: 23.2rem;
        background-image: url('../../../assets/productIntroduction12.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;
      }

      .image_list {
        width: 80%;
        height: 27.2rem;
        display: flex;
        border-radius: 20px;
        box-shadow: 0 0 20px #f4f4f5;

        .image_list_item1 {
          margin-right: 1.6rem;
          width: 25%;
          height: 100%;
          background-image: url('../../../assets/productIntroduction13.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .image_list_item2 {
          margin-right: 1.6rem;
          width: 30%;
          height: 100%;
          background-image: url('../../../assets/productIntroduction14.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .image_list_item3 {
          margin-right: 1.6rem;
          width: 30%;
          height: 100%;
          background-image: url('../../../assets/productIntroduction15.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
</style>