<template>
  <div style="position: fixed;top: 8rem;right: 0rem;z-index: 100;display: flex;">
    <transition name="el-fade-in">
      <div v-show="weChatIsShow" class="wechat">
        <div class="top">
          <div>扫码添加微信</div>
          <div>了解更多详情</div>
        </div>
        <div class="bottom"></div>
      </div>
    </transition>
    <div @click="openWechat" class="contact_us_code">
      <div class="inside_code"></div>
      <div class="contact_us_text">联系我们</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weChatIsShow: false
    }
  },
  methods: {
    openWechat() {
      this.weChatIsShow = !this.weChatIsShow
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.weChatIsShow = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  }
}
</script>

<style lang="scss" scoped>
.contact_us_code {
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.6rem 0.4rem 0.6rem 0.4rem;
  width: 2rem;
  height: 2.4rem;
  background: linear-gradient(to bottom, #bbcbff, #4e78ff);
  cursor: pointer;

  .inside_code {
    background-image: url('../../assets/外层二维码.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 1.4rem;
    height: 1.4rem;
  }

  .contact_us_text {
    font-size: 0.48rem;
    color: white;
  }
}

.wechat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: white;
  width: 3.2rem;
  height: 5.2rem;
  border-radius: 0.2rem;
  padding: 0.6rem 0.4rem 0.6rem 0.4rem;

  .top {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 1.4rem;
    font-size: 0.48rem;
    color: #999999;
  }

  .bottom {
    width: 100%;
    height: 4.2rem;
    background-image: url('../../assets/二维码.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
</style>