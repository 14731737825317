<template>
  <div class="fotter">
    <div class="fotter_inside">
      <div class="fotter_one_inside">
        <div>地址：广东省广州市天河区黄埔大道汇金金融中心B塔21楼 18/19号</div>
      </div>
    </div>
    <div class="fotter_inside">
      <div class="fotter_two_inside">
        <div>E_MAIL：manager@gdcreate.com</div>
      </div>
    </div>
    <div class="fotter_inside">
      <div class="fotter_two_inside">
        <div>版权所有：广州克里特科技有限公司 Copyright 2023 All rights reserved</div>
      </div>
    </div>
    <div class="fotter_inside">
      <div class="fotter_three_inside">
        <div class="left">
          <div class="left_inside">
            <div class="fotter_two_inside_align">
              备案序号：<a href="https://beian.miit.gov.cn" target="_blank" style="color: white;">粤ICP备2024342005号</a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right_inside">
            <!-- <div>备案序号：粤ICP备14040842号</div>
            <div>粤公网安备 44130202000552号 </div> -->

            <div>
              <img src="../../assets/备案图标.png" width = "20" height="20">
              <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44010602013326" rel="noreferrer" target="_blank" style="color: white;">粤公网安备 44010602013326号</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="QRCode">
      <div class="inside">
        <!-- <el-image :src="code" fit="cover"/> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: require('../../assets/二维码.jpg'),
    }
  }
}
</script>

<style lang="scss" scoped>
.fotter {
  width: 100%;
  max-width: 100%; // 设置最大宽度
  margin: 0 auto;    // 自动左右边距以确保在页面宽度变化时居中
  padding-top: 1.24rem;
  height: 8.72rem;
  background: #252A30;
  font-size: 0.56rem;
  display: flex;
  flex-direction: column; // 垂直排列子元素
  align-items: center;    // 水平居中

  .fotter_inside {
    width: 80%;
    height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center; // 水平居中
    margin-bottom: 0.52rem;
    .fotter_two_inside_align{
      width: 100%;
      white-space: nowrap;
    }
    .fotter_one_inside {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center; // 水平居中
      color: white;
      align-items: center;
    }

    .fotter_two_inside {
      width: 57%;
      height: 100%;
      color: white;
      align-items: center; 
      display: flex;
      justify-content: center; // 水平居中
      
    }

    .fotter_three_inside {
  width: 70%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center; // 水平居中
  align-items: center; // 垂直居中

  .left {
    width: 70%;
    display: flex;
    justify-content: center; // 水平居中
    align-items: center; // 垂直居中
    white-space: nowrap; // 禁止换行
    .left_inside {
      display: flex;
      justify-content: center; // 水平居中
      align-items: center;
      margin-bottom: 10px; 
    }
  }

  .right {
    width: 70%;
    display: flex;
    justify-content: center; // 水平居中
    align-items: center; // 垂直居中
    white-space: nowrap; // 禁止换行
    .right_inside {
      display: flex;
      justify-content: center; // 水平居中
      align-items: center;
    }
  }
}

// 关于备案信息的部分
.right_inside {
  display: flex;
  align-items: center; // 垂直居中
  img {
    margin-right: 5px; // 图片与文字之间的间隔
  }
}
  }

  .QRCode {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center; // 水平居中
    padding-top: 0.16rem;
    padding-bottom: 0.4rem;

    .inside {
      width: 3.88rem;
      height: 3.88rem;
      border-radius: 5px;

      .el-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>