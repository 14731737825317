<template>
  <div class="home">
    <div class="carousel_container">
      <el-carousel arrow="never" trigger="click">
        <el-carousel-item>
          <div class="carousel_bg_img1">
            <div class="bg_img1_container">
              <div class="image-and-text">
              <img src="../../../assets/克里特易.png" alt="图标" class="kelete"><div class="top">数字化运营平台</div>
            </div>
              <div class="middle">知 进 度 · 提 效 率 · 控 成 本 · 防 风 险</div>
              <div class="bottom">立 即 了 解 ></div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_bg_img2">
            <div class="bg_img2_container">
              <div class="top">10年专注协同管理软件领域</div>
              <div class="middle">中国数字化协同运营平台及云服务厂商</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_bg_img3"></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="question">
      <div class="title">企业在管理中普遍面临的问题</div>
      <div class="subtitle">利用数字化手段实现多方信息对称、提高项目协作效率</div>
      <div class="question_content">
        <div class="question_content_top">
          <div class="question_content_top_inside">
            <div class="title">企业数据收集难</div>
            <div class="bg_img"></div>
            <div class="content">企业内部数据多而杂，数据的填报依赖不同工作人员，缺乏业务协同；</div>
            <div class="bg_img2_contact">
              <div class="bg_img2"></div>
            </div>
          </div>
          <div class="question_content_top_inside">
            <div class="title">企业成本管控难</div>
            <div class="bg_img"></div>
            <div class="content">企业运营过程成本时刻都在发生，无法实时反映内部成本变化从而做好预算监控；</div>
            <div class="bg_img2_contact">
              <div class="bg_img3"></div>
            </div>
          </div>
          <div class="question_content_top_inside">
            <div class="title">工作沟通协作难</div>
            <div class="bg_img"></div>
            <div class="content">日常工作沟通协作平台较多，无法保持信息一致，从而实现有效协同； </div>
            <div class="bg_img2_contact">
              <div class="bg_img4"></div>
            </div>
          </div>
          <div class="question_content_top_inside">
            <div class="title">企业知识沉淀难</div>
            <div class="bg_img"></div>
            <div class="content">企业发展过程经验缺少有效的整理和沉淀，成果无法复用； </div>
            <div class="bg_img2_contact">
              <div class="bg_img5"></div>
            </div>
          </div>
          <div class="question_content_top_inside">
            <div class="title">需求响应调整难</div>
            <div class="bg_img"></div>
            <div class="content">企业在不同的阶段对需求响应速度有不同的要求</div>
            <div class="bg_img2_contact">
              <div class="bg_img6"></div>
            </div>
          </div>
        </div>
        <div class="question_content_middle">
          <div class="question_content_middle_inside">
            <div class="arrow"></div>
          </div>
          <div class="question_content_middle_inside">
            <div class="arrow"></div>
          </div>
          <div class="question_content_middle_inside">
            <div class="arrow"></div>
          </div>
          <div class="question_content_middle_inside">
            <div class="arrow"></div>
          </div>
          <div class="question_content_middle_inside">
            <div class="arrow"></div>
          </div>
        </div>
        <div class="question_content_bottom">大到企业发展中的目标执行，小到一个具体流程的达成，都可以在数字化运营平台进行管理</div>
      </div>
    </div>
    <div class="environment">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 612" class="environment_title">克里特数字化·Java/c#：双核引擎-信创环境</div>
      </transition>
      <div class="environment_contact">
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 612" class="environment_contact_inside environment_contact_img1">
            <div class="left">
              <div class="left_bg">
                <div class="left_bg_img1"></div>
              </div>
            </div>
            <div class="right">
              <div class="right_title">前端</div>
              <div class="right_container">H5版-JS+jquery+layUI</div>
              <div class="right_container">Vue2版- JS+jquery</div>
              <div class="right_container">Vue3版-TS+ant</div>
              <div class="right_container">打印PDF生成工具</div>
              <div class="right_container">手写签名版、电子签章</div>
              <div class="right_container">WPS组件, VSTO组件，在线编辑</div>
              <div class="right_container">图片在线编辑组件</div>
            </div>
          </div>
        </transition>

        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 612" class="environment_contact_inside environment_contact_img2">
            <div class="left">
              <div class="left_bg">
                <div class="left_bg_img2"></div>
              </div>
            </div>
            <div class="right">
              <div style="margin-bottom:1.4rem;" class="right_title">模块集成</div>
              <div class="right_container">流程中间件</div>
              <div class="right_container">表单引擎</div>
              <div class="right_container">低代码开发平台</div>
              <div class="right_container">大屏、报表</div>
            </div>
          </div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInLeft">
          <div v-show="scrollPosition >= 920" class="environment_contact_inside environment_contact_img3">
            <div class="left">
              <div class="left_bg">
                <div class="left_bg_img3"></div>
              </div>
            </div>
            <div class="right">
              <div class="right_title">集成支持</div>
              <div class="right_container">支持API集成/toolkit</div>
              <div class="right_container">支持代码深度集成</div>
              <div class="right_container">支持URL集成</div>
              <div class="right_container">支持工具包模式集成</div>
              <div class="right_container">支持钉钉、企业微信</div>
              <div class="right_container">单组织、集团版、SAAS版</div>
              <div class="right_container">附件集成: OSS存储、FTP存储</div>
            </div>
          </div>
        </transition>
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
          <div v-show="scrollPosition >= 920" class="environment_contact_inside environment_contact_img4">
            <div class="left">
              <div class="left_bg">
                <div class="left_bg_img4"></div>
              </div>
            </div>
            <div class="right">
              <div style="margin-bottom:1.4rem;" class="right_title">后端语言</div>
              <div class="right_container">Java-SpringBoot</div>
              <div class="right_container">Java-SpringMVC</div>
              <div class="right_container">.net-FrmW系列</div>
              <div class="right_container">.net Core系列</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="core">
      <div class="title"><el-carousel-item>
          <div class="carousel_bg_img1">
            <div class="bg_img1_container">
              <div class="image-and-text">数字化运营平台</div>
              <div class="middle">知 进 度 · 提 效 率 · 控 成 本 · 防 风 险</div>
              <div class="bottom">立 即 了 解 ></div>
            </div>
          </div>
        </el-carousel-item> <div class="duiqi"><img src="../../../assets/克里特易.png" alt="图标" class="keleteType">  核心亮点</div></div>
      <div class="subtitle">让企业上下高效协同协作</div>
      <div class="core_content">
        <div class="inside" style="width: 25%;">
          <div class="inside_item">
            <div class="title">整合企业资源</div>
            <div class="contect">通过数字化技术将企业人、财、物、产、供、销等企业资源集成在一个平台集中管理</div>
          </div>
          <div style="margin-top: 0.8rem;" class="inside_item">
            <div class="title">可靠的安全保障</div>
            <div class="contect">系统建设符合用户对数据安全的要求，相应的技术手段对数据安全和操作安全加以保护，建立完善可靠的安全保障体系 </div>
          </div>
          <div style="border-bottom: 0px solid #E5E5E5;margin-top: 0.8rem;" class="inside_item">
            <div class="title">节约企业资源</div>
            <div class="contect">节约企业的有限资源等，节约企业的人力、物力、财力，降低企业运营成本</div>
          </div>
        </div>
        <div style="align-items: center;justify-content: center;" class="inside">
          <div class="big_img">
            <div class="bg_small_img"></div>
          </div>
        </div>
        <div style="text-align: right;width: 25%;" class="inside">
          <div class="inside_item">
            <div class="title">规范办公流程</div>
            <div class="contect">建立起一个紧密、协调、可靠、简单的管理机制，使办公流程变得规范、有序，消除信息传递中的阻塞、延误、失真 </div>
          </div>
          <div style="margin-top: 0.8rem;" class="inside_item">
            <div class="title">灵活可拓展</div>
            <div class="contect">软件设计要简明，各功能模块间的耦合度小，以适应业务发展需要，便于系统灵活性的继承和扩展 </div>
          </div>
          <div style="border-bottom: 0px solid #E5E5E5;margin-top: 0.8rem;" class="inside_item">
            <div class="title">降低资源配置</div>
            <div class="contect">以经济实用为设计准则，通过最合理的成本实现企业需求，最大限度保护企业在各方面的投入，节省企业的资源成本投入 </div>
          </div>
        </div>
      </div>
    </div>
    <div class="platform">
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 1960" class="title"> <div class="duiqi"><img src="../../../assets/克里特易.png" alt="图标" class="keleteType"> 平台支撑</div></div>
      </transition>
      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDown">
        <div v-show="scrollPosition >= 1960" class="subtitle">满足各类管理场景，可以方便进行调整与扩展，赋予企业数字化应用构建的能力</div>
      </transition>

      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInUp">
        <div v-show="scrollPosition >= 2120" class="platform_content">
          <div class="platform_content_item" style="margin-bottom: 1.2rem;">
            <div class="top">
              <div class="bg_img_container">
                <div class="bg_img1"></div>
              </div>
              <div class="top_title">组织引擎</div>
              <div class="top_subtitle">支撑项目组织</div>
              <div class="double_arrow"></div>
            </div>
          </div>
          <div class="platform_content_item" style="margin-top: 1.2rem;">
            <div class="top">
              <div class="bg_img_container">
                <div class="bg_img2"></div>
              </div>
              <div class="top_title">流程引擎</div>
              <div class="top_subtitle">流程驱动项目过程</div>
              <div class="double_arrow"></div>
            </div>
          </div>
          <div class="platform_content_item" style="margin-bottom: 1.2rem;">
            <div class="top">
              <div class="bg_img_container">
                <div class="bg_img3"></div>
              </div>
              <div class="top_title">知识引擎</div>
              <div class="top_subtitle">项目文件统一归集</div>
              <div class="double_arrow"></div>
            </div>
          </div>
          <div class="platform_content_item" style="margin-top: 1.2rem;">
            <div class="top">
              <div class="bg_img_container">
                <div class="bg_img4"></div>
              </div>
              <div class="top_title">门户引擎</div>
              <div class="top_subtitle">支撑多角色项目门户</div>
              <div class="double_arrow"></div>
            </div>
          </div>
          <div class="platform_content_item" style="margin-bottom: 1.2rem;">
            <div class="top">
              <div class="bg_img_container">
                <div class="bg_img5"></div>
              </div>
              <div class="top_title">移动引擎</div>
              <div class="top_subtitle">快速灵活 · 自动适配</div>
              <div class="double_arrow"></div>
            </div>
          </div>
          <div class="platform_content_item" style="margin-top: 1.2rem;">
            <div class="top">
              <div class="bg_img_container">
                <div class="bg_img6"></div>
              </div>
              <div class="top_title">集成引擎</div>
              <div class="top_subtitle">与各类异构系统集成</div>
              <div class="double_arrow"></div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInUp">
        <div v-show="scrollPosition >= 2460" class="curve"></div>
      </transition>
    </div>
    <div class="web_mobile">
      <div class="title">Web端、移动端一体化平台 </div>
      <div class="subtitle">化繁为简，一点生成，多段运用，轻松将企业管理数字化</div>
      <div class="swiper">
        <el-carousel class="bottom_swiper" arrow="always" trigger="click">
          <el-carousel-item>
            <div class="swiper_img_container">
              <div class="swiper_img"></div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <weChart />
  </div>
</template>

<script>
import weChart from '../../../components/WeChart/index.vue'
export default {
  data() {
    return {
      scrollPosition: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY;
    });
  },
  components: {
    weChart
  }
}
</script>

<style lang="scss" scoped>
.home {
  .carousel_container {
    height: 24rem;
    min-width: 1024px;
    .carousel_bg_img1 {
      height: 100%;
      background-image: url('../../../assets/homeBanner1.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .bg_img1_container {
        padding-left: 3.6rem;
        width: 90%;
        height: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
          font-size: 2.4rem;
          font-weight: 550;
          position: relative; // 设置相对定位
        }
        .image-and-text {
             display: flex; // 使用 Flexbox 来管理子元素的布局
             align-items: center; // 垂直居中对齐子元素
          }
        .kelete{
        width: 300px;
        margin-right: 12px;
        margin-top: 4px;
        }
        .middle {
          font-weight: 550;
          font-size: 1.42rem;
        }

        .bottom {
          width: 6.6rem;
          height: 2rem;
          background: #4e78ff;
          border-radius: 0.64rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 1rem;
          padding: 0px 0.8rem;
        }
      }
    }

    .carousel_bg_img2 {
      height: 100%;
      background-image: url('../../../assets/homeBanner2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .bg_img2_container {
        padding-top: 2rem;
        padding-left: 3.6rem;
        width: 90%;
        height: 38%;
        color: #376ccd;

        .top {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 1.2rem;
        }

        .middle {
          font-size: 1rem;
        }
      }
    }

    .carousel_bg_img3 {
      height: 100%;
      background-image: url('../../../assets/homeBanner3.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .question {
    min-width: 1024px;
    padding-top: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.6rem;

    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 2.4rem
    }

    .question_content {
      width: 75%;

      .question_content_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4rem;

        .question_content_top_inside {
          width: 14%;
          padding: 0.8rem 0.8rem 0.4rem 0.8rem;
          border: 0.04rem solid #E5E5E5;

          .title {
            font-weight: 600;
            font-size: 0.72rem;
            margin-bottom: 0.4rem;
          }

          .bg_img {
            margin-bottom: 0.4rem;
            width: 4.4rem;
            height: 0.2rem;
            background-image: url('../../../assets/rectangle.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          .content {
            height: 4rem;
            line-height: 1rem;
            font-size: 0.56rem;
            color: #999999;
            margin-bottom: 0.8rem;
          }

          .bg_img2_contact {
            display: flex;
            justify-content: flex-end;

            .bg_img2 {
              background-image: url('../../../assets/home1.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              height: 1.6rem;
              width: 1.64rem;
            }

            .bg_img3 {
              background-image: url('../../../assets/home2.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              height: 1.68rem;
              width: 1.6rem;
            }

            .bg_img4 {
              background-image: url('../../../assets/home3.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              height: 1.68rem;
              width: 1.24rem;
            }

            .bg_img5 {
              background-image: url('../../../assets/home4.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              height: 1.68rem;
              width: 1.68rem;
            }

            .bg_img6 {
              background-image: url('../../../assets/home5.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              height: 1.6rem;
              width: 1.68rem;
            }

          }
        }
      }

      .question_content_middle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.8rem;

        .question_content_middle_inside {
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0.8rem;

          .arrow {
            width: 1.4rem;
            height: 1.2rem;
            background-image: url('../../../assets/arrow.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }

      .question_content_bottom {
        height: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #dae2ff;
        color: #4e78ff;
        font-size: 0.56rem;
      }
    }
  }

  .environment {
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F4F3F3;
    padding-bottom: 2.4rem;

    .environment_title {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }

    .environment_contact {
      width: 75%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .environment_contact_inside {
        width: 47%;
        height: 10.2rem;
        margin-bottom: 0.8rem;
        padding: 0.4rem;
        display: flex;

        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-right: 2.4rem;
          margin-left: 1.2rem;

          .left_bg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
            background: #fff8f2;

            .left_bg_img1 {
              width: 2.4rem;
              height: 2rem;
              background-image: url('../../../assets/home7.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .left_bg_img2 {
              width: 2.4rem;
              height: 2rem;
              background-image: url('../../../assets/home8.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .left_bg_img3 {
              width: 2.4rem;
              height: 2rem;
              background-image: url('../../../assets/home6.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .left_bg_img4 {
              width: 2.4rem;
              height: 2rem;
              background-image: url('../../../assets/home9.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }
        }

        .right {
          .right_title {
            margin: 0.8rem 0px 0.8rem 0px;
            font-size: 0.72rem;
            font-weight: 600;
          }

          .right_container {
            font-size: 0.56rem;
            color: #999999;
            margin-bottom: 0.2rem;
          }
        }
      }

      .environment_contact_img1 {
        background-image: url('../../../assets/home19.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .environment_contact_img2 {
        background-image: url('../../../assets/home20.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .environment_contact_img3 {
        background-image: url('../../../assets/home21.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .environment_contact_img4 {
        background-image: url('../../../assets/home22.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .duiqi{
       display: flex; // 使用 Flexbox 来管理子元素的布局
       align-items: center; // 垂直居中对齐子元素
    }
  .core {
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.6rem;
    margin-bottom: 3.2rem;
    .duiqi{
       display: flex; // 使用 Flexbox 来管理子元素的布局
       align-items: center; // 垂直居中对齐子元素
    }
    .keleteType{
      width: 105px;
      margin-right: 15px;
    }
    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
      
    }

    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 2.4rem
    }

    .core_content {
      width: 80%;
      display: flex;
      justify-content: space-between;

      .inside {
        width: 33%;
        height: 13.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .big_img {
          width: 90%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url('../../../assets/home11.png');
          background-size: 100% 90%;
          background-repeat: no-repeat;


          .bg_small_img {
            // background-image: url('../../../assets/home10.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 35%;
            height: 35%;
          }
        }

        .inside_item {
          height: 32%;
          border-bottom: 0.04rem solid #E5E5E5;
          color: #333333;

          .title {
            font-weight: 600;
            font-size: 0.72rem;
            margin-bottom: 0.4rem;
          }

          .contect {
            font-size: 0.56rem;
          }
        }
      }
    }
  }

  .platform {
    background: #F4F3F3;
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.4rem;

    .keleteType{
      width: 105px;
      margin-right: 15px;
    }
    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: 0.56rem;
      color: #999999;
      margin-bottom: 2.4rem
    }

    .platform_content {
      width: 80%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.4rem;

      .platform_content_item {
        width: 14%;
        .top {
          background: white;
          height: 10.4rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          .bg_img_container {
            height: 40%;
            display: flex;
            align-items: center;
            justify-content: center;

            .bg_img1 {
              width: 1.6rem;
              height: 1.2rem;
              background-image: url('../../../assets/home12.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .bg_img2 {
              width: 1.6rem;
              height: 1.6rem;
              background-image: url('../../../assets/home13.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .bg_img3 {
              width: 2rem;
              height: 1.6rem;
              background-image: url('../../../assets/home14.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .bg_img4 {
              width: 2rem;
              height: 1.6rem;
              background-image: url('../../../assets/home15.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .bg_img5 {
              width: 1.2rem;
              height: 1.6rem;
              background-image: url('../../../assets/home16.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }

            .bg_img6 {
              width: 1.6rem;
              height: 1.6rem;
              background-image: url('../../../assets/home17.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
          }

          .top_title {
            font-size: 0.8rem;
            font-weight: 600;
            margin-bottom: 0.4rem;
          }

          .top_subtitle {
            font-size: 0.56rem;
            color: #999999;
            margin-bottom: 1.6rem;
          }

          .double_arrow {
            width: 1.6rem;
            height: 0.4rem;
            background-image: url('../../../assets/home23.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }
      }

    }

    .curve {
      width: 80%;
      height: 2.4rem;
      background-image: url('../../../assets/home18.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-bottom: 2.8rem;
    }
  }

  .web_mobile {
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.4rem;
    margin-bottom: 3.2rem;

    .title {
      margin-bottom: 0.6rem;
      font-size: 0.88rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: 0.56rem;
      color: #999999;
    }

    .swiper {
      width: 85%;

      .swiper_img_container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150%;

        .swiper_img {
          width: 85%;
          height: 100%;
          background-image: url('../../../assets/home24.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

      }
    }
  }
}

::v-deep .el-carousel__container {
  height: 24rem;
}
</style>