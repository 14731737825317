<template>
  <div id="app">
    <el-backtop :visibility-height="100">
      <i style="color: #4e78ff;" class="el-icon-caret-top"></i>
    </el-backtop>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    if (window.innerWidth < 1024) {
      this.$router.replace('/mobile')
    } else {
      this.$router.replace('/pc/home')
    }
  }

}
</script>